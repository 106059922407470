@import "@/styles";

.opensea {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
}

.opensea_img {
  position: relative;
  width: 2.6rem;
  height: 2.6rem;
  margin-right: 0.6rem;
}

.more {
  background: #c1cee3;
  border-radius: 2.7rem;
  width: 23.8rem;
  height: 5.3rem;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  color: #fff;
  position: relative;
  left: 8rem;
  bottom: 1rem;
  cursor: pointer;

  .arrow_right {
    margin-left: 1.3rem;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #fff;
    }
  }

  &:hover,
  &:focus {
    background: #d8e2ed;
    color: #c1cee3;

    .arrow_right svg {
      fill: #c1cee3;
    }
  }
}

.leesunshin_img {
  cursor: pointer;
  position: relative;
  width: 26rem;
  height: 26rem;
}

.modal {
  height: 22.8rem;
  background: white;
  border: 3px solid #c1cee3;
  border-radius: 1rem;
  display: flex;

  .block {
    margin: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    @include font-size(2rem);
    font-weight: 700;
    width: 13.2rem;
    text-align: center;
  }

  .more {
    width: 15.4rem;
    height: 5.3rem;
    position: inherit;
    margin-top: 2rem;
  }
}
