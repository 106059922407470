@import "@/styles";
.wrapper {
  position: relative;
  height: 17.3rem;
}

.news {
  width: 39.2rem;
  height: 13.8rem;
  box-shadow: 3px 3px 25px rgba(193, 206, 227, 0.6);
  border-radius: 1rem;

  > div {
    border-radius: 1rem;
  }
  > div > div > div {
    overflow: hidden;
  }
}

.label {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 16.2rem;
  left: 32rem;
  width: 6rem;
  height: 2.4rem;
  background: #ea3f27;
  box-shadow: 3px 3px 25px rgba(193, 206, 227, 0.6);
  border-radius: 5px 5px 0px 0px;
}
.story,
.tutorial {
  width: 39.2rem;
  height: 13.8rem;
  background: #ffffff;
  box-shadow: 3px 3px 25px rgba(193, 206, 227, 0.6);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  .yeonghee_img {
    position: relative;
    width: 12.7rem;
    height: 13.2rem;
    align-self: flex-end;
  }
  .story_title {
    max-width: 20rem;
    font-weight: 700;
    font-size: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .story_subtitle {
    max-width: 20rem;
    margin-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: #797979;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .arrow {
    width: 0;
    height: 0;

    position: relative;
    align-self: flex-end;
    margin-left: auto;
    border-bottom: 5.8rem solid rgba(57, 61, 102, 0.15);
    border-left: 5.8rem solid transparent;

    background: #c1cee3;
    box-shadow: inset 0px 4px 6px rgba(57, 61, 102, 0.15);

    border-bottom-right-radius: 10px;

    .inner {
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      border-top: 5.8rem solid white;
      border-right: 5.8rem solid transparent;
    }
  }

  .arrow_img {
    width: 1.1rem;
    height: 1.1rem;
    position: relative;
    top: 3.5rem;
    right: 2.5rem;
  }
}

.tutorial {
  height: 13.8rem;
  padding: 0 4rem 0 3rem;
  background: rgba(237, 243, 249, 0.8);

  overflow: hidden;
  &::before {
    content: "";
    background-image: url("/tutorial.png");
    background-size: cover;
    opacity: 0.2;
    position: absolute;
    border-radius: 1rem;

    top: -2.2rem;
    left: -2.2rem;
    right: 0px;
    bottom: 0px;
    width: 44.3rem;

    @include mobile {
      width: 37rem;
    }
  }

  .play {
    margin-left: calc(100% - 31rem);
    @include mobile {
      margin-left: calc(100% - 26rem);
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: -35px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  font-family: "slick", "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI", Sans-serif;
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  color: #c1cee3;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  font-size: 14px;
  color: #ea3f27;
}
.slick-dots :global(li.slick-active) button:before {
  color: #ea3f27;
  font-size: 14px;
}

.next_arrow,
.prev_arrow {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 5.5rem;
  cursor: pointer;
}

.next_arrow {
  right: -4rem;
}

.prev_arrow {
  left: -4rem;
  transform: rotate(180deg);
}

@include mobile {
  .news {
    width: 32.5rem;
    height: 10.9rem;
  }

  .story,
  .tutorial {
    width: 32.5rem;
    height: 10.9rem;

    .yeonghee_img {
      width: 9.9rem;
      height: 10.3rem;
    }

    .story_title {
      width: 14rem;
      max-width: 14rem;
      @include font-size(1.5rem);
    }
    .story_subtitle {
      max-width: 14rem;
      @include font-size(1.2rem);
    }
  }
  .label {
    width: 5.2rem;
    height: 1.8rem;

    bottom: 12.7rem;
    left: 26rem;
    @include font-size(1.2rem);
  }

  .next_arrow,
  .prev_arrow {
    top: 4.5rem;
  }

  .next_arrow {
    right: -3rem;
  }
  .prev_arrow {
    left: -3rem;
    transform: rotate(180deg);
  }
}
