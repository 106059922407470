@import "@/styles";

.info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr fit-content(20%);
  grid-gap: 4.6rem;
  row-gap: 1.5rem;
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25.2rem;
  height: 23px;
  padding: 5px 2rem;
  cursor: pointer;

  color: #797979;
  font-weight: 700;
  font-size: 1.3rem;

  background: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 25px rgba(193, 206, 227, 0.5);
  border-radius: 1rem;

  .eth {
    margin-right: 0.7rem;
    display: flex;
    align-items: center;
  }

  .value {
    color: black;
    display: flex;
    align-items: center;

    .copy {
      margin-left: 0.95rem;
    }
  }
}

@include mobile {
  .mobile_info {
    margin-top: 20px;

    border-radius: 1rem;
    overflow: hidden;
  }
  .block {
    box-shadow: 3px 3px 25px rgba(193, 206, 227, 0.5);
    width: 25.7rem;
    padding: 1rem 3.4rem;
    margin: 0 auto;
    @include font-size(1.3rem);
  }
  .next_arrow,
  .prev_arrow {
    width: 0.7rem;
    height: 1.2rem;
    position: absolute;
    top: 15px;
    cursor: pointer;
    z-index: 1;
  }

  .next_arrow {
    right: calc(50% - 15.5rem);
  }

  .prev_arrow {
    left: calc(50% - 15.5rem);
    transform: rotate(180deg);
  }
}
