@import "@/styles";

.questionBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  border-top: 1px solid #c1cee380;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
  }
  .iconQ {
    color: red;
    font-size: 2.2rem;
  }
  .question {
    margin: 0 0 0 2.5rem;
    font-size: 1.6rem;
    font-weight: 700;
  }
}
.questionBlock:nth-of-type(1) {
  border-top: 0;
}

.answerBlock {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include font-size(1.6rem);
  height: 0px;
  opacity: 0;
  padding: 0 4rem 0 4.2rem;
  transform: translateY(-50%);
  transition: all 0.3s;
  overflow: hidden;
  white-space: pre-line;

  .answer {
    color: #797979;
  }
}

.open {
  opacity: 1;
  padding: 0px 4rem 1.5rem 4.2rem;
  height: auto;
  transform: translateY(0%);
  transition: all 0.3s;
}

.IconLess {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.IconMore {
  transition: all 0.3s;
}

@include mobile {
  .questionBlock {
    padding: 1rem 0 1rem;

    .IconLess {
      width: 1.5rem;
      height: 1.5rem;
    }
    .IconMore {
      width: 1.5rem;
      height: 1.5rem;
    }
    .iconQ {
      font-size: 1rem;
    }
    .question {
      margin: 0 0 0 1rem;
      font-size: 1.2rem;
    }
  }

  .answerBlock {
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: 0px 2.5rem 0px 1.8rem;
    height: 0px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .open {
    padding: 0px 2.5rem 1rem 1.8rem;
    height: auto;
    transform: translateY(0%);
    transition: all 0.3s;
  }
}
