@import "@/styles";

.wrapper {
  display: flex;
  width: 90rem;
  height: 34rem;

  @include mobile {
    height: 14rem;
  }
}

.image {
  width: 25rem;
  height: 45rem;
  position: absolute;
  left: calc(50% - 10rem);
  filter: drop-shadow(0px -15px 35px rgba(193, 206, 227, 0.6));

  @include mobile {
    width: 10rem;
    height: 20rem;
    left: calc(50% - 5rem);
  }

  &:nth-child(4) {
    z-index: -1;
  }
  &:nth-child(5) {
    z-index: -2;
  }
}
