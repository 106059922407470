/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
@import "@/styles";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about {
  width: calc(100% - 13.4rem);
  height: 53rem;
  background: #ffffff;
  border-radius: 30px;
  z-index: 0;

  display: flex;
  padding: 6rem;

  .card_img {
    position: relative;
    width: 25rem;
    height: 52.9rem;
  }

  .bar {
    color: red;
  }

  .title_wrapper {
    display: flex;
  }

  .title {
    margin-left: 6rem;
    font-size: 3rem;
    font-weight: 700;
  }

  .more {
    margin-left: 45rem;
    background: #edf3f9;
    border-radius: 2.7rem;
    width: 13.2rem;
    height: 5.3rem;
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1cee3;

    .arrow_right {
      margin-left: 1.3rem;
      width: 1.6rem;
      height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #c1cee3;
      }
    }

    &:hover,
    &:focus {
      background: #d8e2ed;
      color: #fff;

      .arrow_right svg {
        fill: #fff;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 35rem 35rem;
    grid-gap: 12.6rem;
    row-gap: 9.3rem;
    margin: 7.5rem 6.2rem;

    .grid_title {
      font-size: 2.2rem;
      font-weight: 700;
      color: #ea3f27;
    }

    .description {
      margin-top: 3rem;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
}

@include mobile {
  .about {
    padding: 2rem;
    border-radius: 0;
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 5.3rem;

    .card_img {
      margin: 2rem auto;
      width: 18.1rem;
      height: 38.7rem;
    }

    .title {
      @include font-size(1.5rem);
      margin: 0 auto;
      text-align: center;
    }

    .more {
      margin: 4rem auto 0;
      width: 32.5rem;
    }
  }

  .mobile_wrapper {
    .grid_title {
      @include font-size(1.5rem);
      text-align: center;
      font-weight: 700;
      color: #ea3f27;
    }

    .description {
      margin-top: 2rem;
      @include font-size(1.5rem);
      text-align: center;
    }

    .slick-dots {
      position: absolute;
      bottom: -25px;

      display: block;

      width: 100%;
      padding: 0;
      margin: 0;

      list-style: none;

      text-align: center;
    }
    .slick-dots li {
      position: relative;

      display: inline-block;

      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;

      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;

      display: block;

      width: 20px;
      height: 20px;
      padding: 5px;

      cursor: pointer;

      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 6px;
      line-height: 20px;

      position: absolute;
      top: 0;
      left: 0;

      width: 20px;
      height: 20px;

      content: "•";
      text-align: center;

      color: #e9e9e9;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-dots li button:hover,
    .slick-dots li button:focus {
      outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
      font-size: 14px;
      color: #ea3f27;
    }
    .slick-dots :global(li.slick-active) button:before {
      color: #ea3f27;
      font-size: 14px;
    }
  }
}
