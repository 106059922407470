@import "@/styles";

.title {
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 700;
  .bar {
    color: red;
  }
}

.logo {
  display: flex;
  justify-content: space-between;

  .main_logo_img {
    width: 75.6rem;
    height: 14.6rem;
    position: relative;
    right: 5rem;

    @include mobile {
      right: 0;
    }
  }
}

.description {
  margin-top: 5rem;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #797979;
  white-space: pre;
}

@include mobile {
  .title {
    margin: 4rem auto 2rem;
    @include font-size(1.5rem);
  }
  .logo {
    flex-direction: column-reverse;
    align-items: center;

    .main_logo_img {
      width: 32.5rem;
      height: 6.3rem;
      margin: auto;
    }
  }

  .description {
    @include font-size(1.2rem);
    margin: 2rem 0.3rem 0;
    text-align: center;
  }
}
