@import "@/styles";

.milestone {
  position: relative;
  min-height: 35rem;
  padding: 9rem 0;
  width: 100%;

  .bar {
    color: red;
  }

  .title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
  }

  .milestoneContainer {
    position: relative;
    display: flex;
    margin-top: 3rem;
    width: 100%;
    padding: 0 10rem 0 10rem;

    @include mobile {
      padding: 0;
    }

    .quarter {
      .quarterTitle {
        font-size: 2.2rem;
        font-weight: 700;
        padding: 0 0 1.5rem 8rem;

        @include mobile {
          padding: 0 0 1.7rem 0;
          text-align: center;
        }
      }

      .stoneContainer {
        padding-top: 2rem;
        display: flex;

        &.overlap {
          padding-top: 0.5rem;
        }
      }

      .categoryTitle {
        font-size: 2rem;
        font-weight: 700;
        border-right: 1px solid #c1cee380;
        padding-right: 2rem;
        width: 6rem;
        text-align: right;
        @include mobile {
          @include font-size(1.2rem);
          padding-right: 1rem;
        }
      }
      .stones {
        max-width: calc(23vw - 4.3rem);
        padding-left: 2rem;
        flex: 1;
        @include mobile {
          max-width: 20rem;
        }
      }
      .stone {
        white-space: pre-line;
        @include font-size-15();
        margin-bottom: 5px;
        max-width: calc(23vw - 4.3rem);
        @include mobile {
          max-width: 20rem;
          @include font-size(1.2rem);
        }
      }
      .stone:last-child {
        margin-bottom: 0px;
      }
      .milestoneTitle {
        display: flex;
        align-items: center;
        @include font-size-15();
        font-weight: 700;
      }
      .checkIcon {
        margin-left: 3px;
        transform: translateY(3px);
        width: 16px;
        height: 16px;
        overflow: visible;
      }
    }
  }

  .milestoneLine {
    position: absolute;
    top: 21rem;
    border-bottom: 1px solid #ea3f27;
    width: calc(100% + 13.4rem);
    left: -6.7rem;
    padding: -10rem;
  }

  .info {
    text-align: end;
    margin-top: 1rem;
  }

  .milestoneBackground {
    position: absolute;
    top: 8rem;
    left: 2rem;
    background-image: url("/bg_symbol.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: calc(100vw - 18rem);
    z-index: -1;
    opacity: 0.5;
    @include mobile {
      position: absolute;
      top: 4rem;
      left: -11rem;
      bottom: 90px;
      background-image: url("/bg_symbol_small.png");
      background-size: contain;
      width: 60rem;
      z-index: -1;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #c1cee3;
    position: absolute;
    top: 12.5rem;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 4rem;
    z-index: 1;
    cursor: pointer;

    @include mobile {
      top: 17.3rem;
    }

    &.prev {
      left: 6rem;

      @include mobile {
        left: 0;
      }
    }

    &.next {
      right: 6rem;

      @include mobile {
        right: 0;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
