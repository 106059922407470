@import "@/styles";

.faq {
  height: auto;
  overflow: hidden;
  padding: 90px 0 6rem;
  position: relative;

  .bar {
    color: red;
  }

  .title {
    font-size: 3rem;
    font-weight: 700;
  }

  .faqContainer {
    margin-top: 5rem;
  }
}

@include mobile {
  .faq {
    padding: 0;

    .title {
      margin: 0;
      text-align: center;
      font-size: 1.2rem;
    }
    .faqContainer {
      margin-top: 2rem;
    }
  }
}
