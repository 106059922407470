@import "@/styles";

.container {
  padding: 0 6.7rem;
  @include mobile {
    padding: 0 2.5rem;
  }
}

.main {
  padding: 10rem 0 15rem;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 3rem 0 5rem;
  }
}
